import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EnvironmentInjector,
    OnInit,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor, WebView } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';
import { AlertController, IonRouterOutlet, Platform, ViewDidEnter } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';
import { PrincipalService } from './auth/principal.service';
import { User } from './core/model/user.model';
import { environment } from './environments/environment';
import { ChatUIService } from './pages/chat/chat-ui-service.service';
import { LoginService } from './pages/login/login.service';
import { Translations } from './providers/translate';
import { UserData } from './providers/user-data';
import { EventManager } from './service/event-manager.service';
import { FcmService } from './service/fcm.service';
import { GatewayService } from './service/gateway.service';
import { IonLoaderService } from './service/loader.service';
import { NotificationService } from './service/notification.service';
import { PreferenceService } from './service/preference.service';
import { SharedService } from './service/shared.service';
import { isStudent } from './util/utils';

register();
//It must be before @Component - https://github.com/ngneat/until-destroy
@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, ViewDidEnter {
    showProgress = false;

    isStudent;

    loggedIn = false;
    dark = false;
    account: User;
    loading = false;
    appUpdateInfo: AppUpdateInfo;
    @ViewChild(IonRouterOutlet, { static: true }) private routerOutlet?: IonRouterOutlet;
    constructor(
        private platform: Platform,
        private router: Router,
        private storage: Storage,
        private userData: UserData,
        private em: EventManager,
        public environmentInjector: EnvironmentInjector,
        private loaderService: IonLoaderService,
        private _translateService: TranslateService,
        private gatewayService: GatewayService,
        private cdr: ChangeDetectorRef,
        private fcmService: FcmService,
        private principal: PrincipalService,
        private loginService: LoginService,
        private sharedService: SharedService,
        private notificationService: NotificationService,
        public translations: Translations,
        private alertController: AlertController,
        private preference: PreferenceService,
        private destroyRef: DestroyRef,
        private chatUiService: ChatUIService
    ) {
        this.storage.create().then(() => {});
        this.principal.init();
        this.isStudent = isStudent();
    }

    async ngOnInit() {
        this.loggedIn = this.preference.isAuthenticated();
        this.preference.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(state => {
            this.loggedIn = state.user && state.authenticated;
            console.log('APP-LOGGEDIN', this.loggedIn);
            this.account = state.user;
            if (this.account?.langKey) {
                console.log('TRANSLATE', this.account);
                this._translateService.use(this.account?.langKey);
                this.cdr.markForCheck();
            }
            console.log('User state changed-App', this.loggedIn);
            this.cdr.detectChanges();
        });
        this.setupAppListener();
        this.initializeApp();
        this.sharedService.init();
        console.log('PATH', (await WebView.getServerBasePath()).path);

        this.listenForLoginEvents();

        this.em.subscribe(
            'show_login_progress',
            () => {
                //this.loading = true;
                this.loaderService.simpleLoader(this._translateService.instant('LOGIN.MESSAGE.LOGGING_IN'));
            },
            untilDestroyed(this)
        );
        this.sharedService.changeApiProgress.subscribe(show => {
            console.log('data', show);
            this.showProgress = show;
            this.cdr.detectChanges();
        });

        this.em.subscribe(
            'logout-user',
            () => {
                console.log('Logout-App');
                this.logout();
            },
            untilDestroyed(this)
        );
    }

    ionViewDidEnter() {
        if (this.platform.is('capacitor') && Capacitor.getPlatform() != 'web') {
            AppUpdate.getAppUpdateInfo().then(appUpdateInfo => {
                this.appUpdateInfo = appUpdateInfo;
            });
        }
    }

    async setupAppListener() {
        App.addListener('appStateChange', ({ isActive }) => {
            console.log('APP-STATE-BACKGROUND', isActive);
            if (!isActive) {
                this.chatUiService.currentChatUrl.next(null);
                this.notificationService.background.next(true);
                // App went to background - LocalNotification
                // Save anything you fear might be lost
            } else {
                // App went to foreground -AlertController
                // restart things like sound playing
                this.notificationService.background.next(false);
                this.fcmService.clearBadge();
                this.updateLoggedInStatus();
            }
        });
    }

    initializeApp() {
        console.log('PLATFORMS', this.platform.platforms());
        /*
    if (this.platform.is("desktop")) {
      environment.apiUrl = "";
    }
    */

        this.platform.backButton.subscribeWithPriority(10, () => {
            console.log('BackButton was called!', this.router.url);
            if ((!this.principal.isAuthenticated() && this.router.url == '/login') || !this.routerOutlet.canGoBack()) {
                this.router.navigateByUrl('home');
                this.backButtonAlert();
            }
            if (!this.loggedIn) {
                this.router.navigateByUrl('login');
            }
            if (this.routerOutlet.canGoBack()) {
                window.history.back();
            }
        });

        this.platform.ready().then(() => {
            if (this.platform.is('hybrid')) {
                // StatusBar.hide();
                SplashScreen.hide();
            }
        });

        this.em.subscribe(
            'active-school-changed',
            result => {
                console.log('ACTIVE_USER', result?.content);
                if (result?.content?.activeSchool) {
                    this.account = result?.content;
                }
            },
            untilDestroyed(this)
        );

        this.em.subscribe(
            'user-connection-status-changed',
            data => {
                console.log('CONNECTION-STATUS', data);
                this.storage.get(data.message.message.id).then(user => {
                    if (user) {
                        user.active = data.message.message.connected;
                        this.storage.remove(data.message.message.id);
                        this.storage.set(data.message.message.id, user);
                    } else {
                        this.storage.remove(data.message.message.id);
                    }
                    this.em.broadcast({
                        name: 'user-connection-changed-image',
                        data: data.message.message,
                    });
                    this.cdr.detectChanges();
                });
            },
            untilDestroyed(this)
        );

        this.em.subscribe(
            'show-notification-event',
            content => {
                this.notificationService.notify({
                    id: content.message.id,
                    title: content.message.title,
                    body: content.message.message,
                });
            },
            untilDestroyed(this)
        );
    }

    async backButtonAlert() {
        const alert = await this.alertController.create({
            message: this._translateService.instant('GENERAL.MESSAGE.CONFIRM.EXIT_APP'),
            buttons: [
                {
                    text: this._translateService.instant('GENERAL.BUTTON.CLOSE'),
                    role: 'yes',
                    handler: () => {
                        App.exitApp();
                    },
                },
                {
                    text: this._translateService.instant('GENERAL.BUTTON.CANCEL'),
                    role: 'cancel',
                    handler: () => {},
                },
            ],
        });
        await alert.present();
    }

    updateLoggedInStatus() {
        if (this.loggedIn) {
            this.account = this.userData.getUser();
            console.log('UPDATE-STATUS', this.account);
            if (this.account) {
                if (this.account?.token) {
                    this.loginService.checkAccessToken(this.account);
                }
                this.gatewayService.serverInfo().subscribe(result => {
                    environment.serverInfo = result;
                    environment.serverInfo.userTimeZone = new Date().getTimezoneOffset() * -1;
                });
                this._translateService.setDefaultLang(this.account.langKey);
                this._translateService.getTranslation(this.account.langKey);
                if (this.platform.is('capacitor') && Capacitor.getPlatform() != 'web') {
                    console.log('FCM INIT');
                    //this.fcmService.initPush();
                }
            }
            console.log('CURRENT-URL', this.router.url);
            if (this.router.url.indexOf('/login') > -1) {
                console.log('HOME CALL');
                this.router.navigateByUrl('app/home');
            }
            console.log('user has already logged in');
            this.cdr.detectChanges();
        } else {
            this.account = null;
            console.log('login page');
            this.router.navigateByUrl('/login');
        }
    }

    listenForLoginEvents() {
        window.addEventListener('user:login', () => {
            this.updateLoggedInStatus();
        });

        window.addEventListener('user:signup', () => {
            this.updateLoggedInStatus();
        });

        window.addEventListener('user:logout', () => {
            this.updateLoggedInStatus();
        });
    }

    logout() {
        console.log('LOGOUT-App');
        this.loginService.logout();
    }

    loadProfile() {}
}
