import { AttendanceByStudentType } from './attendanceByStudentType';
import { CalendarEvent } from './calendar-event.model';
import { AttendanceInfo } from './course-student-attendance.model';
import { TranslatableString } from './translateble-string.model';

export class AttendanceByCourseSearch {
    constructor(
        public courseIds?: string[],
        public studentId?: string,
        public schoolId?: string,
        public academicPeriodId?: string,
        public type?: AttendanceByStudentType,
        public date?: Date,
        public dateRange?: Range,
        public periodShortNames?: string[]
    ) {}
}

export class AttendanceByCourse {
    constructor(
        public info?: AttendanceInfo,
        public studentIds?: string[],
        public courseEventId?: string
    ) {}
}

export class CourseStudentAttendancesByDateDTO {
    constructor(
        public studentInfo?: CourseStudentInfo[],
        public courseEvent?: CalendarEvent,
        public shouldNotExpand?: boolean
    ) {}
}

export class CourseStudentInfo {
    constructor(
        public studentId?: string,
        public studentNo?: string,
        public firstName?: string,
        public imageUrl?: string,
        public translateFirstName?: TranslatableString[],
        public middleName?: string,
        public translateMiddleName?: TranslatableString[],
        public lastName?: string,
        public translateLastName?: TranslatableString[],
        public highlight?: boolean
    ) {}
}
