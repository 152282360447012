import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    AlertController,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonRouterLink,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { IUser } from '../../../core/model/user.model';
import { UserData } from '../../../providers/user-data';
import { DailyCalendarComponent } from '../../slider-daily-calendar/daily-calendar';

@Component({
    selector: 'teacher-page-home',
    templateUrl: 'teacher-home.html',
    styleUrls: ['./teacher-home.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        FontAwesomeModule,
        DailyCalendarComponent,
        IonRouterLink,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonMenuButton,
        IonTitle,
        IonContent,
        IonRefresher,
        IonRefresherContent,
        IonGrid,
        IonRow,
        IonCol,
        IonButton,
    ],
})
export class TeacherHomePage {
    username: string;
    account: IUser;
    loading = false;
    constructor(
        public alertCtrl: AlertController,
        public router: Router,
        public userData: UserData
    ) {
        console.log('TEACHERHOME PAGE');
    }

    handleRefresh(event) {
        // if (this.account?.activeSchool?.id) {
        //   this.loadEvents(event);
        // }
    }
}
