import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginGuard } from './auth/login.guard';
import { environment } from './environments/environment';
import { StudentHomePage } from './pages/home/student/student-home';
import { TeacherHomePage } from './pages/home/teacher/teacher-home';
import { isStudent, isTeacher } from './util/utils';
const routes: Routes = [
    {
        path: 'profile',
        loadComponent: () => import('./pages/profile/profile.component').then(m => m.UserProfilePagePage),
    },
    {
        path: 'settings',
        children: [
            {
                path: '',
                loadComponent: () => import('./pages/settings/settings.component').then(m => m.SettingsPagePage),
            },
            {
                path: 'notifications',
                loadComponent: () =>
                    import('./pages/settings/notifications/notifications.component').then(
                        m => m.NotificationSettingsPage
                    ),
            },
            {
                path: 'languages',
                loadComponent: () =>
                    import('./pages/settings/languages/languages.component').then(m => m.LanguageSettingsPage),
            },
            {
                path: 'password-change',
                loadComponent: () =>
                    import('./pages/settings/password-change/password-change.component').then(
                        m => m.PasswordChangePage
                    ),
            },
            {
                path: 'platforms',
                loadComponent: () => import('./pages/platform/platform.component').then(m => m.PlatformPagePage),
            },
            {
                path: 'schools',
                loadComponent: () => import('./pages/settings/schools/schools.component').then(m => m.SchoolSwitchPage),
            },
        ],
    },
    {
        path: 'account',
        loadComponent: () => import('./pages/account/account').then(m => m.AccountPageComponent),
    },
    {
        path: 'support',
        loadComponent: () => import('./pages/support/support').then(m => m.SupportPage),
    },
    {
        path: 'login',
        loadComponent: () => import('./pages/login/login').then(m => m.LoginPage),
        canActivate: [LoginGuard],
    },
    {
        path: 'signup',
        loadComponent: () => import('./pages/signup/signup').then(m => m.SignupPage),
    },
    {
        path: 'chat',
        loadComponent: () => import('./pages/chat/chat.component').then(m => m.ChatComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'grade',
        loadComponent: () => import('./pages/new-grading/grading.component').then(m => m.GradingComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'grade-detail/:periodType/:periodId/:courseId',
        loadComponent: () =>
            import('./pages/new-grading/grade-detail/grade-detail.component').then(m => m.GradeDetailComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'chat/:entityId',
        loadComponent: () => import('./pages/chat/chat-detail/chat-detail.component').then(m => m.ChatDetailComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'messages',
        loadComponent: () => import('./pages/messages/messages-list.component').then(m => m.MessagesListComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'messages/:id',
        loadComponent: () =>
            import('./pages/messages/message-detail/message-detail.component').then(m => m.MessageDetailComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'message-compose',
        loadComponent: () =>
            import('./pages/messages/message-compose/message-compose.component').then(m => m.MessageComposeComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'message-compose/reply/:id',
        loadComponent: () =>
            import('./pages/messages/message-compose/message-compose.component').then(m => m.MessageComposeComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'daily-attendance-detail',
        loadComponent: () =>
            import('./pages/attendance/daily-detail/daily-attendance').then(m => m.DailyAttendancePage),
        canMatch: [isStudent],
    },
    {
        path: 'announcement',
        loadComponent: () => import('./pages/announcement/announcement.component').then(m => m.AnnouncementComponent),
    },
    {
        path: 'announcement/:id',
        loadComponent: () =>
            import('./pages/announcement/announcement-detail/announcement-detail.component').then(
                m => m.AnnouncementDetailComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'courses-detail/:courseId',
        loadComponent: () => import('./pages/courses/details/course-details').then(m => m.CourseDetailsPage),
        canActivate: [AuthGuard],
        children: [
            {
                path: 'schedule',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./pages/courses/details/tabs/schedule/course-schedule.component').then(
                                m => m.CourseDetailSchedulePage
                            ),
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: 'assignments',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./pages/courses/details/tabs/assignments/course-assignments.component').then(
                                m => m.CourseDetailAssignmentsPageComponent
                            ),
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: 'attendance',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./pages/courses/details/tabs/attendance/course-attendance.component').then(
                                m => m.CourseDetailAttendancePage
                            ),
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: 'grades',
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./pages/courses/details/tabs/grades/course-grades.component').then(
                                m => m.CourseDetailGradesPage
                            ),
                        canActivate: [AuthGuard],
                    },
                ],
            },
        ],
    },
    {
        path: 'app/tabs',
        loadComponent: () => import('./pages/tabs-page/tabs-page').then(m => m.TabsPage),
        canActivate: [AuthGuard],
        children: [
            {
                path: 'courses',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./pages/courses/courses').then(m => m.CourseListPage),
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: 'schedule',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./pages/schedule/schedule').then(m => m.SchedulePage),
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: 'assignment',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./pages/assignment/student-assignment/student-assignment.page'),
                        canMatch: [isStudent],
                    },
                ],
            },
            {
                path: 'attendance',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./pages/attendance/attendance').then(m => m.AttendanceListPage),
                        canMatch: [isStudent],
                    },
                ],
            },
            {
                path: 'about',
                loadComponent: () => import('./pages/about/about').then(m => m.AboutPageComponent),
            },
        ],
    },
    {
        path: 'app/course-grades',
        loadComponent: () =>
            import('./pages/grading/student-grades/course-grades/course-grades.page').then(
                m => m.CourseGradesPageComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'app/course-grades/:courseId',
        loadComponent: () =>
            import('./pages/grading/student-grades/course-grades/course-grade-details/course-grade-details.page').then(
                m => m.CourseGradesDetailsPageComponent
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'app/finance/student-fees',
        loadComponent: () =>
            import('./pages/finance/student-fees/student-fees.page').then(m => m.StudentFeesPageComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'app/finance/student-fees/:id',
        loadComponent: () =>
            import('./pages/finance/student-fees/details/student-fee-details.page').then(
                m => m.StudentFeeDetailsPageComponent
            ),
        canActivate: [AuthGuard],
        children: [
            {
                path: 'progress',
                loadComponent: () =>
                    import('./pages/finance/student-fees/details/progress/progress.page').then(
                        m => m.StudentFeeDetailsProgressPageComponent
                    ),
                canActivate: [AuthGuard],
                canMatch: [isStudent],
            },
            {
                path: 'payment-plan',
                loadComponent: () =>
                    import('./pages/finance/student-fees/details/payment-plan/payment-plan.page').then(
                        m => m.StudentFeeDetailsPaymentPlanPage
                    ),
                canActivate: [AuthGuard],
                canMatch: [isStudent],
            },
            {
                path: 'fees',
                loadComponent: () =>
                    import('./pages/finance/student-fees/details/fees/fees.page').then(m => m.FeesPage),
                canActivate: [AuthGuard],
                canMatch: [isStudent],
            },
        ],
    },
    {
        path: 'assignment-detail',
        children: [
            {
                path: ':assignmentId',
                loadComponent: () =>
                    import(
                        './pages/assignment/student-assignment/student-assignment-detail/student-assignment-detail.page'
                    ).then(m => m.StudentAssignmentDetailPage),
                canActivate: [AuthGuard],
                canMatch: [isStudent],
                children: [
                    {
                        path: 'info',

                        loadComponent: () =>
                            import(
                                './pages/assignment/student-assignment/student-assignment-detail/tabs/info/info.page'
                            ).then(m => m.StudentAssignmentInfoPage),
                        canActivate: [AuthGuard],
                        canMatch: [isStudent],
                    },
                    {
                        path: 'submissions',
                        loadComponent: () =>
                            import(
                                './pages/assignment/student-assignment/student-assignment-detail/tabs/submissions/submissions.page'
                            ).then(m => m.StudentAssignmentSubmissionsPage),
                        canActivate: [AuthGuard],
                        canMatch: [isStudent],
                    },
                    { path: '', redirectTo: 'info', pathMatch: 'full' },
                ],
            },
        ],
    },
    {
        path: 'schedule-detail',
        children: [
            {
                path: ':id',
                loadComponent: () =>
                    import('./pages/courses/details/tabs/schedule/detail/schedule-detail.component').then(
                        m => m.CourseScheduleDetailSchedulePage
                    ),
                canActivate: [AuthGuard],
                canMatch: [isStudent],
            },
        ],
    },
    {
        path: 'app/home',
        component: StudentHomePage,
        canMatch: [isStudent],
        canActivate: [AuthGuard],
    },
    {
        path: 'app/home',
        component: TeacherHomePage,
        canMatch: [isTeacher],
        canActivate: [AuthGuard],
    },
    {
        path: 'app/home',
        component: StudentHomePage,
        canActivate: [AuthGuard],
    },
    {
        path: '',
        redirectTo: '/app/home',
        pathMatch: 'full',
    },
    { path: '**', redirectTo: '/app/home' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false, //, preloadingStrategy: PreloadSelectedModules,
            scrollPositionRestoration: 'top',
            scrollOffset: [0, 0],
            enableTracing: !environment.production,
            //bindToComponentInputs: true,
            // Enable scrolling to anchors
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
