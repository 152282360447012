<ion-header>
    <ion-toolbar color="dark">
        <ion-title>{{ translations.GENERAL.FIELD.ATTENDANCE | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()" [strong]="true">{{
                translations.GENERAL.BUTTON.CLOSE | translate
            }}</ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar color="dark" class="ion-padding">
        <div class="column">
            <h1>{{ event.eventTitle }}</h1>
            <div style="color: gainsboro">
                {{ translations.GENERAL.FIELD.PERIOD | translate }}: {{ event.periodShortName }} ~
                {{ event.eventDate | dateTimeFormat }} ~ {{ event.eventStartTime + '-' + event.eventEndTime }}
            </div>
        </div>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    @if (attendeeList?.studentInfo?.length > 0) {
        <ion-grid>
            <ion-row>
                @for (studentInfo of attendeeList.studentInfo; track studentInfo) {
                    <ion-col (click)="onStudentSelected(studentInfo)" size="4" size-md="3" size-lg="2">
                        <div
                            class="column studentInfo"
                            [ngStyle]="{ 'background-color': studentInfo.highlight ? '#e1bee7' : '' }">
                            <user-image
                                style="margin-bottom: 4px"
                                [miniAvatar]="true"
                                [user]="{ id: studentInfo.studentId }"
                                slot="start"></user-image>
                            <div style="width: 100%; font-size: small; margin-bottom: 4px">
                                {{ studentInfo.lastName + ' ' + studentInfo.firstName }}
                            </div>
                            <div
                                [ngClass]="{
                                    attendance_attended_color:
                                        getAttendance(studentInfo).type == AttendanceType.PRESENT,
                                    attendance_tardy_color: getAttendance(studentInfo).type == AttendanceType.TARDY,
                                    attendance_permitted_color:
                                        getAttendance(studentInfo).type == AttendanceType.PERMITTED,
                                    attendance_excused_color: getAttendance(studentInfo).type == AttendanceType.EXCUSED,
                                    attendance_absent_color: getAttendance(studentInfo).type == AttendanceType.ABSENT
                                }"
                                style="font-size: small">
                                {{ getAttendance(studentInfo)?.type | enum: 'ATTENDANCE_TYPE' }}
                            </div>
                        </div>
                    </ion-col>
                }
            </ion-row>
            @if (!saveClicked && isEditable() && !hasSelected()) {
                <ion-button
                    (click)="
                        save(
                            this.attendeeList.courseEvent.courseType == CourseType.OFFLINE
                                ? AttendanceType.PRESENT
                                : AttendanceType.ABSENT,
                            true
                        )
                    "
                    style="margin-top: 12px"
                    expand="block"
                    >{{
                        this.attendeeList.courseEvent.courseType == CourseType.OFFLINE
                            ? (translations.GENERAL.BUTTON.SAVE_AS_PRESENT | translate)
                            : (translations.GENERAL.BUTTON.SAVE_AS_ABSENT | translate)
                    }}
                </ion-button>
            }
            @if (hasSelected() && isEditable()) {
                <div style="width: 100%; margin-top: 12px">
                    <ion-button (click)="save(AttendanceType.PRESENT)" fill="outline" class="attendance_attended_color"
                        >{{ translations.ENUM.ATTENDANCE_TYPE.PRESENT | translate }}
                    </ion-button>
                    <ion-button (click)="save(AttendanceType.ABSENT)" fill="outline" class="attendance_absent_color"
                        >{{ translations.ENUM.ATTENDANCE_TYPE.ABSENT | translate }}
                    </ion-button>
                    <ion-button
                        (click)="save(AttendanceType.PERMITTED)"
                        fill="outline"
                        class="attendance_permitted_color"
                        >{{ translations.ENUM.ATTENDANCE_TYPE.PERMITTED | translate }}
                    </ion-button>
                    <ion-button (click)="save(AttendanceType.EXCUSED)" fill="outline" class="attendance_excused_color"
                        >{{ translations.ENUM.ATTENDANCE_TYPE.EXCUSED | translate }}
                    </ion-button>
                    <ion-button fill="outline" class="attendance_tardy_color"
                        >{{ translations.ENUM.ATTENDANCE_TYPE.TARDY | translate }}
                    </ion-button>
                </div>
            }
        </ion-grid>
    }
</ion-content>
<ion-footer *ngIf="attendeeList?.studentInfo?.length > 0">
    <ion-button
        *ngIf="!saveClicked && isEditable() && !hasSelected()"
        (click)="
            save(
                this.attendeeList.courseEvent.courseType === CourseType.OFFLINE
                    ? AttendanceType.PRESENT
                    : AttendanceType.ABSENT,
                true
            )
        "
        style="margin-top: 12px"
        expand="block"
        >{{
            this.attendeeList.courseEvent.courseType === CourseType.OFFLINE
                ? (translations.GENERAL.BUTTON.SAVE_AS_PRESENT | translate)
                : (translations.GENERAL.BUTTON.SAVE_AS_ABSENT | translate)
        }}
    </ion-button>
    <div *ngIf="hasSelected() && isEditable()" style="width: 100%; margin-top: 12px">
        <ion-button (click)="save(AttendanceType.PRESENT)" fill="outline" class="attendance_attended_color"
            >{{ translations.ENUM.ATTENDANCE_TYPE.PRESENT | translate }}
        </ion-button>
        <ion-button (click)="save(AttendanceType.ABSENT)" fill="outline" class="attendance_absent_color"
            >{{ translations.ENUM.ATTENDANCE_TYPE.ABSENT | translate }}
        </ion-button>
        <ion-button (click)="save(AttendanceType.PERMITTED)" fill="outline" class="attendance_permitted_color"
            >{{ translations.ENUM.ATTENDANCE_TYPE.PERMITTED | translate }}
        </ion-button>
        <ion-button (click)="save(AttendanceType.EXCUSED)" fill="outline" class="attendance_excused_color"
            >{{ translations.ENUM.ATTENDANCE_TYPE.EXCUSED | translate }}
        </ion-button>
        <ion-button (click)="save(AttendanceType.TARDY)" fill="outline" class="attendance_tardy_color"
            >{{ translations.ENUM.ATTENDANCE_TYPE.TARDY | translate }}
        </ion-button>
    </div>
</ion-footer>
