import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PreferenceService } from '../service/preference.service';

export const AuthGuard: CanActivateFn = () => {
    const preference = inject(PreferenceService);
    const router = inject(Router);
    const isLoggedIn = preference.isAuthenticated();
    if (!isLoggedIn) {
        router.navigate(['/login']);
        return false;
    } else {
        return true;
    }
};
