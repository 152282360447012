import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '../providers/translate';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(
        private toastController: ToastController,
        private _translate: TranslateService,
        private transltaions: Translations
    ) {}
    async showToast(message: string, color: ColorType = 'primary', duration: number = 3000) {
        const toast = await this.toastController.create({
            color: color,
            message: message,
            duration: duration,
            translucent: true,
            position: 'bottom',
            layout: 'baseline',
            buttons: [
                { text: this._translate.instant(this.transltaions.GENERAL.BUTTON.CLOSE), role: 'cancel', side: 'end' },
            ],
        });
        await toast.present();
    }

    async showErrorToast(message: string, color: ColorType = 'danger', duration: number = 5000) {
        const toast = await this.toastController.create({
            color: color,
            message: message,
            duration: duration,
            translucent: true,
            position: 'bottom',
            layout: 'baseline',
            buttons: [
                { text: this._translate.instant(this.transltaions.GENERAL.BUTTON.CLOSE), role: 'cancel', side: 'end' },
            ],
        });
        await toast.present();
    }

    async showSuccessToast(message: string, color: ColorType = 'success', duration: number = 5000) {
        const toast = await this.toastController.create({
            color: color,
            message: message,
            duration: duration,
            translucent: true,
            position: 'bottom',
            layout: 'baseline',
            buttons: [
                { text: this._translate.instant(this.transltaions.GENERAL.BUTTON.CLOSE), role: 'cancel', side: 'end' },
            ],
        });
        await toast.present();
    }
}

export type ColorType =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'medium'
    | 'light';
