import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
    AlertController,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonRow,
    IonTitle,
    IonToolbar,
    ModalController,
} from '@ionic/angular/standalone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { UserImageComponent } from '../../../image/user/user-image.component';
import {
    AttendanceByCourseSearch,
    CourseStudentAttendancesByDateDTO,
    CourseStudentInfo,
} from '../../../model/attendance-by-course.model';
import { AttendanceByStudentType } from '../../../model/attendanceByStudentType';
import { AttendanceType } from '../../../model/attendanceType.enum';
import { CalendarEvent } from '../../../model/calendar-event.model';
import { AttendanceInfo } from '../../../model/course-student-attendance.model';
import { CourseType } from '../../../model/courseType.enum';
import { Meeting, MeetingStatusType } from '../../../model/meetings-model.model';
import { DateTimeFormatPipe } from '../../../pipes/datetime-format.pipe';
import { EnumTranslatePipe } from '../../../pipes/enum-translate.pipe';
import { Translations } from '../../../providers/translate';
import { CalendarEventService } from '../../../service/calendar-event.service';
import { PreferenceService } from '../../../service/preference.service';
import { ToastService } from '../../../service/toast.service';
import { SharedConstants } from '../../../util/shared.constants';

@Component({
    selector: 'app-attendance',
    templateUrl: './attendance.component.html',
    styleUrls: ['./attendance.component.css'],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        DateTimeFormatPipe,
        UserImageComponent,
        EnumTranslatePipe,
        TranslateModule,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonGrid,
        IonRow,
        IonCol,
        IonFooter,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendanceComponent implements OnInit {
    @Input() event: CalendarEvent;
    meeting: Meeting;
    attendeeList: CourseStudentAttendancesByDateDTO;
    isModerator = false;
    isHeadTeacher = false;
    AttendanceType = AttendanceType;
    CourseType = CourseType;
    saveClicked = false;
    private unsbscrb: Subject<any> = new Subject<any>();
    constructor(
        private modalCtrl: ModalController,
        public eventService: CalendarEventService,
        private preferenceSerevice: PreferenceService,
        public translations: Translations,
        private toastService: ToastService,
        private translate: TranslateService,
        private alertController: AlertController,
        private cdr: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.isHeadTeacher = this.preferenceSerevice.getUser().roles.includes(SharedConstants.ROLE_HEAD_TEACHER);
        if (this.event) {
            this.eventService
                .getMeetingByCalendarEventIdForOnlineCourse(this.event.id)
                .pipe(takeUntil(this.unsbscrb))
                .subscribe(result => {
                    this.meeting = result;
                    this.isModerator = this.meeting.isModerator;
                    this.setAttendances();
                });
        }
    }

    setAttendances() {
        const searchDTO = new AttendanceByCourseSearch();
        searchDTO.courseIds = [this.event.courseId];
        searchDTO.date = this.event.eventDate;
        searchDTO.periodShortNames = [this.event.periodShortName];
        searchDTO.type = AttendanceByStudentType.PARTIAL_DAY;
        searchDTO.academicPeriodId = this.preferenceSerevice.getUser()?.academicYear?.id;
        searchDTO.schoolId = this.preferenceSerevice.getUser()?.activeSchool?.id;
        this.eventService
            .getCourseEventsForAttendance(searchDTO)
            .pipe(takeUntil(this.unsbscrb))
            .subscribe(result => {
                const list = result.body;
                if (list?.length > 0) {
                    this.attendeeList = list[0];
                    this.cdr.markForCheck;
                    this.fillData();
                }
            });
    }

    close() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }
    fillData() {
        console.log('FILL-DATA', this.attendeeList);
        if (!this.attendeeList) {
            return;
        }
        this.attendeeList?.studentInfo?.forEach(s => {
            const attendance = this.attendeeList.courseEvent.attendanceInfo?.find(a => a.studentId == s.studentId);
            if (attendance) {
                attendance.saved = true;
                if (!attendance.type) {
                    attendance.type =
                        this.attendeeList.courseEvent.courseType == CourseType.OFFLINE
                            ? AttendanceType.PRESENT
                            : AttendanceType.ABSENT;
                }
            } else {
                const ai = {
                    studentId: s.studentId,
                    type:
                        this.attendeeList.courseEvent.courseType == CourseType.OFFLINE
                            ? AttendanceType.PRESENT
                            : AttendanceType.ABSENT,
                    displayNoteToStudent: false,
                    saved: false,
                };

                this.attendeeList?.courseEvent.attendanceInfo.push(ai);
            }
            this.cdr.markForCheck();
        });
    }
    onStudentSelected(studentInfo) {
        studentInfo.highlight = !studentInfo.highlight;
        this.cdr.markForCheck();
    }
    hasSelected() {
        if (!this.attendeeList?.studentInfo) {
            return;
        }
        return this.attendeeList.studentInfo.find(s => s.highlight);
    }
    getAttendance(row: CourseStudentInfo) {
        if (!this.attendeeList?.courseEvent?.attendanceInfo) {
            return;
        }
        return this.attendeeList.courseEvent.attendanceInfo.find(a => a.studentId == row.studentId);
    }
    async save(type: AttendanceType, all: boolean = false) {
        if (!this.attendeeList?.courseEvent?.attendanceInfo || !this.attendeeList?.studentInfo) {
            return;
        }

        if (all) {
            this.attendeeList?.courseEvent.attendanceInfo.forEach(a => (a.type = type));
        } else {
            const selectedStudents = this.attendeeList.studentInfo.filter(s => s.highlight);
            if (selectedStudents?.length > 0) {
                const attendanceInfos = this.attendeeList.courseEvent.attendanceInfo.filter(
                    s => selectedStudents.findIndex(selected => selected.studentId == s.studentId) > -1
                );
                if (attendanceInfos?.length > 0) {
                    let tardyMinutes = null;
                    if (type === AttendanceType.TARDY) {
                        const result: { data: any; role: string } = await this.setTardyMinutesAlert();
                        if (result.role === 'confirm') {
                            tardyMinutes = Number(result.data.values[0]);
                            console.log(typeof Number(result.data.values[0]));
                        } else {
                            return;
                        }
                    }
                    attendanceInfos.forEach(a => {
                        a.type = type;
                        if (tardyMinutes) {
                            a.durationTardy = tardyMinutes;
                        }
                    });
                }
            }
        }
        const isOffline = this.attendeeList.courseEvent.courseType == CourseType.OFFLINE;
        this.eventService
            .updateStudentAttendance(this.event.id, this.attendeeList?.courseEvent.attendanceInfo, isOffline)
            .pipe(takeUntil(this.unsbscrb))
            .subscribe(() => {
                this.attendeeList.studentInfo.forEach(s => (s.highlight = false));
                const item = this.translate.instant(this.translations.GENERAL.FIELD.ATTENDANCE);
                const message = this.translate.instant(this.translations.GENERAL.MESSAGE.SUCCESS.SAVED, { item });
                this.toastService.showSuccessToast(message);
                this.saveClicked = true;
                this.cdr.markForCheck();
            });
    }
    isEditable() {
        return (
            (this.meeting?.status != MeetingStatusType.ENDED || this.isHeadTeacher || this.isModerator) &&
            this.meeting?.status != MeetingStatusType.CANCELLED
        );
    }
    ionViewWillLeave() {
        this.unsbscrb.next(null);
        this.unsbscrb.complete();
    }

    async setTardyMinutesAlert() {
        const alert = await this.alertController.create({
            header: this.translate.instant(this.translations.MEETINGS.TITLE.TARDY_DURATION),
            inputs: [
                {
                    type: 'number',
                    placeholder: this.translate.instant(this.translations.CALENDAR_EVENT.FIELD.MISSED_MINUTES),
                    min: 1,
                },
            ],
            buttons: [
                {
                    text: this.translate.instant(this.translations.GENERAL.BUTTON.CANCEL),
                    role: 'cancel',
                },
                {
                    text: this.translate.instant(this.translations.GENERAL.TITLE.CONFIRM_DIALOG),
                    role: 'confirm',
                },
            ],
        });
        await alert.present();

        const data = await alert.onDidDismiss();
        if (data.role === 'confirm') {
            const val = data?.data?.values[0] as number;
            if (!val) {
                this.toastService.showErrorToast(
                    this.translate.instant(this.translations.CALENDAR_EVENT.MESSAGE.ENTER_TARDY_DURATION)
                );
                return this.setTardyMinutesAlert();
            }
        }
        return data;
    }
}

export interface AttendanceData {
    studentId: string;
    attendance: AttendanceInfo;
}
