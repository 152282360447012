import { provideHttpClient, withFetch, withInterceptorsFromDi, withNoXsrfProtection } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationRef,
    DoBootstrap,
    ErrorHandler,
    InjectionToken,
    LOCALE_ID,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeKk from '@angular/common/locales/kk';
import localeRu from '@angular/common/locales/ru';
import localeTr from '@angular/common/locales/tr';
import { FormsModule } from '@angular/forms';
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
    HammerModule,
    REMOVE_STYLES_ON_COMPONENT_DESTROY,
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

//import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';
import {
    IonApp,
    IonContent,
    IonHeader,
    IonImg,
    IonMenu,
    IonProgressBar,
    IonRouterOutlet,
    IonSplitPane,
    IonTitle,
    IonToolbar,
    IonicRouteStrategy,
    provideIonicAngular,
} from '@ionic/angular/standalone';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { CapacitorHttpProvider } from './interceptor/capacitor-http.provider';
import { GlobalErrorHandler } from './interceptor/global-error.handler';
import { NavMenuComponent } from './pages/nav-menu/nav-menu.component';
import { ProfilePanel } from './pages/profile-panel/profile-panel';
import { throwIfAlreadyLoaded } from './providers/error';
import { initIcons } from './providers/icons';
import { CustomMissingTranslationHandler, WebpackTranslateLoader, getSelectedLanguage } from './providers/translate';
import { PreferenceService } from './service/preference.service';
import { VersionCheckService } from './service/version-check.service';
import { IonicGestureConfig } from './util/ionic-guesture.config';
export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

export function initAppFactory(
    preference: PreferenceService,
    versionCheck: VersionCheckService,
    _translateService: TranslateService
) {
    return async () => {
        await preference.init();
        //gatewayService.getServices().subscribe(result => {
        /*
    if (result?.mobile) {
      preference.setValue("appUrl", result.mobile);
    }
    console.log('services', result);
    if (result.mobile && !document.location.href.startsWith(result.mobile)) {
      setTimeout(() => {
        document.location.href = result.mobile;
      });
    }
    */
        //console.log('initAppFactory:', result.mobile);
        //});

        _translateService.addLangs(['en', 'kk', 'ru', 'de', 'tr', 'fr']);
        // Set the default language
        _translateService.setDefaultLang('en');

        // Get selected language and load it
        //_translateService.use(getSelectedLanguage(_translateService));

        //const browserLang = _translateService.getBrowserLang();
        //console.log("Browser Lang=", browserLang);
        //_translateService.use(browserLang.match(/en|kk|ru|de|tr|fr/) ? browserLang : "en");
        if (environment.production) {
            versionCheck.startVersionChecking('./assets/version.json', 30 * 60 * 1000);
        }
    };
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ProfilePanel,
        FontAwesomeModule,
        HammerModule,
        NavMenuComponent,
        IonicStorageModule.forRoot({
            driverOrder: [Drivers.SecureStorage, Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
        }),
        IonApp,
        IonProgressBar,
        IonSplitPane,
        IonMenu,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonImg,
        IonContent,
        IonRouterOutlet,
    ],
    providers: [
        provideHttpClient(withFetch(), withInterceptorsFromDi(), withNoXsrfProtection()),
        //provideHttpClient(),
        {
            provide: APP_INITIALIZER,
            useFactory: initAppFactory,
            deps: [PreferenceService, VersionCheckService, TranslateService],
            multi: true,
        },
        { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: true },
        {
            provide: LOCALE_ID,
            useFactory: getSelectedLanguage,
            deps: [TranslateService],
        },
        CapacitorHttpProvider,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: LOCALE_ID,
            useFactory: (translateService: TranslateService) => {
                return translateService.currentLang || translateService.getBrowserLang();
            },
            deps: [TranslateService],
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: IonicGestureConfig,
        },
        provideIonicAngular(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule implements DoBootstrap {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AppModule,
        private faConfig: FaConfig,
        private library: FaIconLibrary
    ) {
        registerLocaleData(localeRu, 'ru');
        registerLocaleData(localeKk, 'kk');
        registerLocaleData(localeDe, 'de');
        registerLocaleData(localeTr, 'tr');
        registerLocaleData(localeFr, 'fr');

        this.faConfig.defaultPrefix = 'fal';
        this.faConfig.fixedWidth = true;
        initIcons(this.library);
        dom.watch();
        throwIfAlreadyLoaded(parentModule, 'AppModule');
    }
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(AppComponent);
    }
}
