import { IconName } from '@fortawesome/fontawesome-svg-core';

export interface Navigation {
    id?: number;
    title?: string;
    url?: string;
    icon?: IconName;
    count?: number;
}

export const teacherNavMenu: Navigation[] = [
    {
        id: 1,
        title: 'GENERAL.BUTTON.HOME',
        url: '/app/home',
        icon: 'home',
    },

    {
        id: 4,
        title: 'NAV.MY_ASSIGNMENTS.TITLE',
        url: '/app/tabs/assignment',
        icon: 'list-check',
        count: 0,
    },

    {
        id: 6,
        title: 'GENERAL.FIELD.CHATS',
        url: '/chat',
        icon: 'message-lines',
        count: 0,
    },
    {
        id: 7,
        title: 'NAV.USER_MESSAGES.TITLE',
        url: '/messages',
        icon: 'envelope',
        count: 3,
    },
    {
        id: 9,
        title: 'TOOLBAR.MENU_ITEMS.SETTINGS',
        url: '/settings',
        icon: 'gear',
    },
];

export const studentNavMenu: Navigation[] = [
    {
        id: 1,
        title: 'GENERAL.BUTTON.HOME',
        url: '/app/home',
        icon: 'home',
    },
    {
        id: 2,
        title: 'NAV.CALENDAR_EVENT.TITLE',
        url: '/app/tabs/schedule',
        icon: 'calendar-clock',
    },
    {
        id: 3,
        title: 'NAV.COURSES.TITLE',
        url: '/app/tabs/courses',
        icon: 'chalkboard-user',
        count: 0,
    },
    {
        id: 4,
        title: 'NAV.MY_ASSIGNMENTS.TITLE',
        url: '/app/tabs/assignment',
        icon: 'list-check',
        count: 0,
    },
    {
        id: 5,
        title: 'NAV.GRADING.TITLE',
        url: '/grade',
        icon: 'chart-mixed',
    },
    {
        id: 6,
        title: 'GENERAL.FIELD.CHATS',
        url: '/chat',
        icon: 'message-lines',
        count: 0,
    },
    {
        id: 7,
        title: 'NAV.USER_MESSAGES.TITLE',
        url: '/messages',
        icon: 'envelope',
        count: 3,
    },
    {
        id: 8,
        title: 'NAV.ANNOUNCEMENTS.TITLE',
        url: '/announcement',
        icon: 'bell',
        count: 0,
    },
    {
        id: 9,
        title: 'TOOLBAR.MENU_ITEMS.SETTINGS',
        url: '/settings',
        icon: 'gear',
    },
];
