@if (_loggedIn) {
    <profile-panel [account]="account"></profile-panel>
    <ion-list lines="none" style="height: 100%; border-bottom: none">
        <ion-list-header> Campus </ion-list-header>
        @for (p of appPages; track p) {
            <ion-menu-toggle autoHide="false">
                <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
                    <fa-icon slot="start" style="font-size: 22px" [icon]="['fal', p.icon]"> </fa-icon>
                    <ion-label>
                        {{ p.title | translate }}
                    </ion-label>
                    @if (p.count) {
                        <ion-badge color="danger" slot="end">{{ p.count }}</ion-badge>
                    }
                </ion-item>
            </ion-menu-toggle>
        }
    </ion-list>
}

@if (!_loggedIn) {
    <ion-list lines="none">
        <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
                <ion-icon slot="start" name="log-in"></ion-icon>
                <ion-label> {{ 'LOGIN.LOGIN' | translate }} </ion-label>
            </ion-item>
        </ion-menu-toggle>
    </ion-list>
}
