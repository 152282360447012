@if (account) {
<ion-grid [fixed]="true" class="profile">
    <ion-row>
        <ion-col size="auto">
            <user-image [user]="account"></user-image>
        </ion-col>
        <ion-col>
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    height: 100%;
                    color: white;
                ">
                <span style="font-size: larger; font-weight: 600; color: white"
                    >{{ account?.firstName }} {{ account?.lastName }}</span
                >
                <span style="font-size: small; line-height: 20px">{{ account?.email }}</span>
                @if (isStudent) {
                <div style="display: flex; align-content: center; color: white">
                    <div style="padding-right: 8px">
                        <span class="description">{{'GENERAL.FIELD.CLASS' | translate}}:</span>
                        <span class="description-data">{{account?.student?.enrolledClass?.name}}</span>
                    </div>
                    <div>
                        <span class="description">{{'GENERAL.FIELD.STUDENT_ID' | translate }}</span>
                        <span class="description-data">:{{account?.student?.studentId}}</span>
                    </div>
                </div>
                }
            </div>
        </ion-col>
    </ion-row>
</ion-grid>
}
