<ion-app [class.dark-theme]="dark">
    @if (showProgress) {
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
    }
    <ion-split-pane contentId="main-content">
        @if (loggedIn) {
            <ion-menu contentId="main-content">
                <ion-header collapse="fade" color="primary">
                    <ion-toolbar>
                        <ion-title style="padding-inline: unset; background: white">
                            <div class="menu-header-content">
                                <ion-img class="menu-logo" src="assets/img/favicon.png"></ion-img>
                                <div style="color: black">
                                    {{ account?.activeSchool?.shortName }} :
                                    {{ account?.academicYear?.name }}
                                </div>
                            </div>
                        </ion-title>
                    </ion-toolbar>
                </ion-header>
                <ion-content>
                    <app-menu [account]="account" [loggedIn]="loggedIn"></app-menu>
                </ion-content>
            </ion-menu>
        }
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
