import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { IonCol, IonGrid, IonRow } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../core/model/user.model';
import { UserImageComponent } from '../../image/user/user-image.component';
import { isStudent } from '../../util/utils';

@Component({
    selector: 'profile-panel',
    templateUrl: 'profile-panel.html',
    styleUrls: ['./profile-panel.scss'],
    standalone: true,
    imports: [FormsModule, UserImageComponent, TranslateModule, IonGrid, IonRow, IonCol],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePanel {
    @Input()
    account: User;
    assignmentCount: number = 0;
    messagesCount: number = 0;
    isStudent = isStudent();
    constructor() {}
}
