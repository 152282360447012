import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import {
    faAddressCard,
    faAlarmClock,
    faAlarmPlus,
    faAnalytics,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArchive,
    faArrowCircleDown,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowToLeft,
    faArrowToRight,
    faArrowsRotate,
    faBallotCheck,
    faBan,
    faBars,
    faBell,
    faBellPlus,
    faBellSlash,
    faBookOpen,
    faBookReader,
    faBooks,
    faBoxes,
    faBracketsCurly,
    faBrush,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarCircleUser,
    faCalendarClock,
    faCalendarDay,
    faCalendarEdit,
    faCalendarTimes,
    faCalendarWeek,
    faCamcorder,
    faCamera,
    faCertificate,
    faChalkboard,
    faChalkboardTeacher,
    faChartBar,
    faChartPieSimple,
    faChartSimple,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleQuestion,
    faCircleSort,
    faCity,
    faClipboardList,
    faClock,
    faCloudDownload,
    faCloudUpload,
    faCodeCompare,
    faCog,
    faCogs,
    faComment,
    faCommentAltDollar,
    faCommentAltLines,
    faCommentDots,
    faCommentPlus,
    faCommentsAlt,
    faCommentsAltDollar,
    faCompressAlt,
    faCopy,
    faCreditCard,
    faCube,
    faCubes,
    faDigging,
    faDownLeftAndUpRightToCenter,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenDollar,
    faEnvelopeOpenText,
    faEraser,
    faExchange,
    faExclamationCircle,
    faExclamationSquare,
    faExclamationTriangle,
    faExpandAlt,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCertificate,
    faFileCheck,
    faFileExcel,
    faFileExport,
    faFileImage,
    faFileImport,
    faFileInvoiceDollar,
    faFilePdf,
    faFilePowerpoint,
    faFileSignature,
    faFileVideo,
    faFileWord,
    faFilm,
    faFlag,
    faFolder,
    faFolderOpen,
    faFolderPlus,
    faFolderTree,
    faFont,
    faFunction,
    faGlobeEurope,
    faGraduationCap,
    faHandHoldingUsd,
    faHandPointer,
    faHandsUsd,
    faHandshakeSimple,
    faHdd,
    faHistory,
    faHome,
    faHomeAlt,
    faIdBadge,
    faImage,
    faInboxIn,
    faInboxOut,
    faInfo,
    faInfoCircle,
    faKey,
    faLanguage,
    faLayerGroup,
    faLink,
    faList,
    faListAlt,
    faListOl,
    faListUl,
    faLocation,
    faLocationDot,
    faLock,
    faLockOpen,
    faMap,
    faMapMarkerAlt,
    faMarker,
    faMemoCircleInfo,
    faMicrophone,
    faMinus,
    faMoneyBill,
    faMoneyBillWave,
    faMoneyCheckAlt,
    faMoneyCheckEdit,
    faMoneyCheckEditAlt,
    faMoon,
    faMusic,
    faObjectGroup,
    faPage,
    faPager,
    faPaperPlane,
    faPaperPlaneTop,
    faPaperclip,
    faPause,
    faPaw,
    faPenField,
    faPenSquare,
    faPeopleCarry,
    faPersonFalling,
    faPersonFallingBurst,
    faPhoneSlash,
    faPlay,
    faPlayCircle,
    faPlus,
    faPoll,
    faPollH,
    faPollPeople,
    faPortalExit,
    faPresentation,
    faQuestionCircle,
    faQuestionSquare,
    faRedoAlt,
    faRepeat,
    faReply,
    faReplyAll,
    faRestroom,
    faRunning,
    faSackDollar,
    faSave,
    faSchool,
    faScreenUsers,
    faScroll,
    faSearch,
    faServer,
    faShareAlt,
    faShoppingBasket,
    faSignIn,
    faSignOut,
    faSignOutAlt,
    faSitemap,
    faSlidersHSquare,
    faSortNumericDownAlt,
    faSpinnerThird,
    faStamp,
    faStarHalfAlt,
    faStop,
    faStopCircle,
    faSync,
    faTable,
    faTasks,
    faText,
    faThList,
    faTimes,
    faTimesCircle,
    faTimesSquare,
    faTrashAlt,
    faTrashRestore,
    faUndo,
    faUndoAlt,
    faUpload,
    faUser,
    faUserAltSlash,
    faUserChart,
    faUserCheck,
    faUserCircle,
    faUserEdit,
    faUserGraduate,
    faUserGroup,
    faUserMinus,
    faUserPlus,
    faUserSlash,
    faUserTag,
    faUserTimes,
    faUsers,
    faUsersClass,
    faUsersCog,
    faUsersMedical,
    faUsersSlash,
    faVideo,
    faVolumeSlash,
    faVolumeUp,
    faWallet,
    faWindowMaximize,
    faWindowRestore,
    faStar as falStar,
    faFolder as fasFolder,
} from '@fortawesome/pro-light-svg-icons';

import { faCircle as fasCircle, faStar as fasStar } from '@fortawesome/pro-solid-svg-icons';

import { faFacebook, faGoogle, faGoogleDrive, faMicrosoft, faYoutube } from '@fortawesome/free-brands-svg-icons';

export function initIcons(library: FaIconLibrary): void {
    library.addIcons(
        fasCircle,
        faMoon,
        fasStar,
        faCalendarClock,
        faBooks,
        faMapMarkerAlt,
        faCircleChevronLeft,
        faCircleChevronRight,
        faArrowToLeft,
        faArrowToRight,
        faPhoneSlash,
        faCamcorder,
        faBookOpen,
        faWhatsapp,
        faPenSquare,
        faPenField,
        faArchive,
        faFolderTree,
        faAnalytics,
        faHdd,
        faGoogleDrive,
        faServer,
        faMicrosoft,
        faGoogle,
        faFacebook,
        faYoutube,
        faUserAltSlash,
        faUserCheck,
        faQuestionSquare,
        faList,
        faRepeat,
        faUserMinus,
        faCommentDots,
        faCommentPlus,
        faChevronDown,
        faChevronUp,
        faCompressAlt,
        faExpandAlt,
        faFileSignature,
        faFilePdf,
        faFileVideo,
        faFileWord,
        faFileImage,
        faFileAudio,
        faFileExcel,
        faFileArchive,
        faFilePowerpoint,
        faMicrophone,
        faCircle,
        faCalendarTimes,
        faCalendarWeek,
        faUsersSlash,
        faUserChart,
        faVideo,
        faPortalExit,
        faHandPointer,
        faChevronDoubleRight,
        faChevronDoubleLeft,
        faEnvelopeOpenDollar,
        faUserPlus,
        faEraser,
        faCogs,
        faMoneyBill,
        faMoneyBillWave,
        faExchange,
        faInfo,
        faEnvelope,
        faRunning,
        faFileImport,
        faDigging,
        faClipboardList,
        faTimesSquare,
        faWindowRestore,
        faWindowMaximize,
        faFileExport,
        faSignOutAlt,
        faSave,
        faSync,
        faPeopleCarry,
        faCalendar,
        faEye,
        faEyeSlash,
        faSignOut,
        faKey,
        faUserCircle,
        faAngleDown,
        faArrowDown,
        faBars,
        faLanguage,
        faEllipsisV,
        faPlus,
        faEdit,
        faTrashAlt,
        faTimes,
        faSearch,
        faHome,
        faCheck,
        faCommentsAltDollar,
        faCity,
        faCommentAltDollar,
        faObjectGroup,
        faSitemap,
        faCalendarDay,
        faUsersCog,
        faRestroom,
        faCalendarAlt,
        faCalendarEdit,
        faHandsUsd,
        faMoneyCheckAlt,
        faChalkboardTeacher,
        faChalkboard,
        faMoneyCheckEdit,
        faTasks,
        faBallotCheck,
        faAngleLeft,
        faArrowRight,
        faArrowLeft,
        faAlarmClock,
        faAngleRight,
        faBookReader,
        faCamera,
        faFileCertificate,
        faChevronRight,
        faChevronLeft,
        faCheckCircle,
        faExclamationTriangle,
        faFileExcel,
        faFunction,
        faFolder,
        faFolderOpen,
        faFolderPlus,
        faInfoCircle,
        faPage,
        faSchool,
        faSignIn,
        faTimesCircle,
        faUser,
        faUserEdit,
        faUpload,
        faGlobeEurope,
        faUserTag,
        faFlag,
        faListAlt,
        faListOl,
        faListUl,
        faDownload,
        faFileInvoiceDollar,
        faSortNumericDownAlt,
        faGraduationCap,
        faPaperclip,
        faMoneyCheckEditAlt,
        faUsersClass,
        faLocation,
        faBuilding,
        faUsers,
        faUserGraduate,
        faHandHoldingUsd,
        faPollPeople,
        faWallet,
        faHistory,
        faUndoAlt,
        faUndo,
        faUserTimes,
        faArrowCircleDown,
        faBoxes,
        faCubes,
        faCube,
        faBrush,
        faShareAlt,
        faPause,
        faStop,
        fasStar,
        falStar,
        faStarHalfAlt,
        faPlay,
        faSackDollar,
        faCheckSquare,
        faUserSlash,
        faThList,
        faExclamationSquare,
        faEnvelopeOpenText,
        faText,
        faCopy,
        faClock,
        faPresentation,
        faBan,
        faBell,
        faBellSlash,
        faScroll,
        faCog,
        faRedoAlt,
        faEnvelopeOpen,
        faCloudDownload,
        faCloudUpload,
        faPaperPlane,
        faInboxIn,
        faInboxOut,
        faReplyAll,
        faShoppingBasket,
        faMarker,
        faReply,
        faCommentAltLines,
        faFile,
        faAddressCard,
        faCommentsAlt,
        faFont,
        faSlidersHSquare,
        faMap,
        faComment,
        faLock,
        faLockOpen,
        faExclamationCircle,
        faCalendarCheck,
        faUsersMedical,
        faLayerGroup,
        faLink,
        faTrashRestore,
        faPlayCircle,
        faPollH,
        faMinus,
        faQuestionCircle,
        faAlarmPlus,
        faVolumeUp,
        faVolumeSlash,
        faSpinnerThird,
        faFileCheck,
        faCircleCheck,
        faChartBar,
        faTable,
        faHomeAlt,
        faLockOpen,
        faCircleSort,
        faChartSimple,
        faChartPieSimple,
        faIdBadge,
        faBellPlus,
        faDownLeftAndUpRightToCenter,
        faPager,
        faUserGroup,
        faExternalLinkAlt,
        faHandshakeSimple,
        faBracketsCurly,
        faCircleQuestion,
        faMemoCircleInfo,
        faPoll,
        faStamp,
        faCertificate,
        faMusic,
        faImage,
        faLocationDot,
        faStopCircle,
        faPaw,
        faFilm,
        faPersonFallingBurst,
        faPersonFalling,
        fasFolder,
        faArrowsRotate,
        faScreenUsers,
        faCodeCompare,
        faCalendarCircleUser,
        faCreditCard,
        faUndo,
        faPaperPlaneTop
    );
}
