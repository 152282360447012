import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Platform } from '@ionic/angular/standalone';
import { environment } from '../environments/environment';
import { IonLoaderService } from '../service/loader.service';
import { SharedService } from '../service/shared.service';
import { ToastService } from '../service/toast.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    static readonly REFRESH_PAGE_ON_TOAST_CLICK_MESSAGE: string =
        'An error occurred: Please click this message to refresh';
    static readonly DEFAULT_ERROR_TITLE: string = 'Something went wrong';
    chunkFailedMessage = /Loading chunk/;

    constructor(
        private _loaderService: IonLoaderService,
        private _translate: TranslateService,
        private platform: Platform,
        private _sharedService: SharedService,
        private _toastService: ToastService
    ) {}

    public handleError(error: any): void {
        console.log('ERROR', error);
        if (/Loading chunk [\d]+ failed/i.test(error?.message)) {
            window.location.reload();
        }
        let newError = error?.error ? error.error : error;
        if (this.platform.is('capacitor') && error?.error?.length > 0 && error?.error != 'undefined') {
            newError = JSON.parse(error?.error);
        }
        if (!navigator.onLine && environment.production) {
            // Handle offline error
            this.showError('Browser is offline.Please check your internet connection');
            return;
        }
        if (this.chunkFailedMessage.test(error?.message)) {
            if (navigator.onLine) {
                window.location.reload();
            } else {
                if (environment.production) {
                    this.showError('Browser is offline.Please check your internet connection');
                }
                return;
            }
            return;
        }

        const httpErrorCode = error?.status;
        //ERR_NETWORK_IO_SUSPENDED
        if (httpErrorCode == 0 && window.navigator.onLine) {
            window.location.reload();
        }
        if (error instanceof HttpErrorResponse && httpErrorCode) {
            switch (httpErrorCode) {
                case HttpStatusCode.Unauthorized: // StatusCodes.UNAUTHORIZED:
                    this._loaderService.dismissLoader();
                    break;
                case HttpStatusCode.Forbidden: // StatusCodes.FORBIDDEN:
                    this.showError(newError?.message ? newError.message : error.message);
                    break;
                case HttpStatusCode.BadRequest: // StatusCodes.BAD_REQUEST:
                    //console.log('BADREQUEST', error?.error);
                    this.showError(newError.message ? newError.message : newError.detail);
                    break;
                case HttpStatusCode.BadGateway: // StatusCodes.BAD_GATEWAY:
                    this._toastService.showErrorToast(
                        'Service is temporarily unavailable. Please wait a bit and try again later'
                    );
                    break;
                case HttpStatusCode.ServiceUnavailable: // StatusCodes.BAD_GATEWAY:
                    this._toastService.showErrorToast(
                        'Service is temporarily unavailable. Please wait a bit and try again later'
                    );
                    break;
                case HttpStatusCode.InternalServerError: // StatusCodes.INTERNAL_SERVER_ERROR:
                    if (
                        newError?.message &&
                        (newError.message == 'COMMAND_EXCEPTION' || newError.message == 'GENERAL')
                    ) {
                        newError.message =
                            'Now, one or more service not running or yet starting. Please wait and try again later';
                    }
                    this.showError(newError?.detail ? newError.detail : newError.message);
                    break;
                case HttpStatusCode.NotFound: // StatusCodes.NOT_FOUND:
                    if (newError?.message == 'error.http.404') {
                        this._toastService.showErrorToast(
                            'Service is temporarily unavailable. Please wait a bit and try again later'
                        );
                    } else {
                        this._toastService.showErrorToast(
                            newError?.message
                                ? newError?.message
                                : 'Service is temporarily unavailable. Please try again later'
                        );
                    }
                    //this.showError(error.message);
                    break;
                case HttpStatusCode.GatewayTimeout: // GATEWAY_TIMEOUT:
                    this._toastService.showErrorToast(this._translate.instant('GENERAL.MESSAGE.ERROR.GATEWAY_TIMEOUT'));
                    break;
                default:
                    this.showError(
                        newError?.message ? newError.message : error.message ? error.message : newError.detail
                    );
            }
            //this.traceError(error.error, 'SERVER-SIDE');
        } else {
            this.showError(this.getMessageFromUnknownError(error));
            //this.traceError(error, 'CLIENT-SIDE');
        }
        this._loaderService.dismissLoader();
        this._sharedService.changeApiProgress.next(true);
    }

    private getMessageFromUnknownError(error: unknown): string {
        let message = 'An unknown error has occurred.';
        if (error instanceof Object && 'rejection' in error) {
            error = (error as any).rejection;
        }
        if (error instanceof Error && error.message) {
            message = error.message;
        }
        return message;
    }

    private showError(message: string) {
        console.warn('Error-Message : ', message);
        if (message == '"WebView" plugin is not implemented on web') {
            return;
        }
        if (message == 'Not implemented on web.') {
            return;
        }
        if (message == '"PushNotifications" plugin is not implemented on web') {
            return;
        }
        if (message) {
            this._toastService.showErrorToast(message);
        }
        this._sharedService.changeApiProgress.next(false);
        this._loaderService.dismissLoader();
    }
}
