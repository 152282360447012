<ion-grid class="ion-no-padding">
    <ion-row>
        <ion-col class="ion-no-padding">
            @if (_isLoading) {
            <div style="background: #0642ac; width: 100%; display: flex; height: 80px; justify-content: center">
                <ion-spinner style="background: #0642ac" name="circles" color="light"></ion-spinner>
            </div>
            } @if (!_isLoading && _events?.length > 0) {
            <div class="swiper-wrapper">
                <swiper-container #swiper swiperElement [config]="config" init="false" style="height: 90px">
                    @for (event of _events; track event) {
                    <swiper-slide
                        (click)="selectEvent(event)"
                        [ngClass]="{'swiper-slide-selected': event.referenceId == 'ACTIVE'}">
                        <div>
                            <div
                                stylvge="
                  padding-bottom: 12px;
                  font-weight: 500;
                  white-space: nowrap;
                ">
                                {{ event.eventTitle }}
                            </div>
                            <div>
                                <div style="white-space: nowrap">{{ event.eventStartTime | timeFormat: format}}</div>
                                <div style="white-space: nowrap">{{ event.eventEndTime | timeFormat: format}}</div>
                            </div>
                        </div>
                    </swiper-slide>
                    }
                </swiper-container>
            </div>
            }
        </ion-col>
    </ion-row>
</ion-grid>
