<ion-header collapse="fade" class="toolbar-shadow">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{'GENERAL.BUTTON.HOME' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content padding>
    <ion-refresher slot="fixed" [pullFactor]="0.5" [pullMin]="100" [pullMax]="200" (ionRefresh)="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <app-daily-calendar></app-daily-calendar>
    <ion-grid>
        <ion-row>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/app/tabs/schedule" class="menu-item">
                    <fa-icon class="menu-item-icon" [icon]="['fal', 'calendar-clock']"></fa-icon>
                    <div class="menu-item-caption">
                        <div>{{'NAV.CALENDAR_EVENT.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/app/tabs/courses" class="menu-item">
                    <fa-icon
                        class="menu-item-icon"
                        style="padding-right: 8px"
                        [icon]="['fal', 'chalkboard-user']"></fa-icon>
                    <div class="menu-item-caption">
                        <div style="padding-right: 4px">{{'NAV.COURSES.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/grade" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'chart-mixed']"></fa-icon>
                    <div class="menu-item-caption">
                        <div>{{'NAV.GRADING.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/app/tabs/attendance" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'screen-users']"></fa-icon>
                    <div class="menu-item-caption">
                        <div>{{'NAV.ATTENDANCE.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/app/tabs/assignment" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'list-check']"></fa-icon>
                    <div class="menu-item-caption">
                        <div style="padding-right: 4px">{{'NAV.MY_ASSIGNMENTS.TITLE' | translate}}</div>
                    </div>
                    @if (totalAssignments > 0 ) {
                    <ion-badge color="danger" class="item-badge">{{totalAssignments}}</ion-badge>
                    }
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button routerLink="/app/finance/student-fees" color="tertiary" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'money-check-dollar-pen']"></fa-icon>
                    <div class="menu-item-caption">
                        <div>{{'NAV.STUDENT_FINANCE.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/messages" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'envelope']"></fa-icon>
                    <div class="menu-item-caption">
                        <div style="padding-right: 4px">{{'NAV.USER_MESSAGES.TITLE' | translate}}</div>
                    </div>
                    @if (unreadMessageCount>0) {
                    <ion-badge color="danger" class="item-badge">{{unreadMessageCount}}</ion-badge>
                    }
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button routerLink="/announcement" color="tertiary" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'bell']"></fa-icon>
                    <div class="menu-item-caption">
                        <div style="padding-right: 4px">{{'NAV.ANNOUNCEMENTS.TITLE' | translate}}</div>
                    </div>
                    @if (unreadAnnouncementCount>0) {
                    <ion-badge color="danger" class="item-badge">{{unreadAnnouncementCount}}</ion-badge>
                    }
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/chat" class="menu-item">
                    <fa-icon slot="start" class="menu-item-icon" [icon]="['fal', 'comment-dots']"></fa-icon>
                    <div class="menu-item-caption">
                        <div style="padding-right: 4px">{{translations.GENERAL.FIELD.CHATS| translate}}</div>
                    </div>
                    @if (unreadChatMessageCount>0) {
                    <ion-badge color="danger" class="item-badge">{{unreadChatMessageCount}}</ion-badge>
                    }
                </ion-button>
            </ion-col>
            <ion-col size="6"> </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
