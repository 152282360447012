<ion-grid class="calendar">
    <ion-row style="flex-wrap: nowrap">
        <ion-col style="padding-left: 10px" class="ion-padding-horizontal ion-no-padding date-row">
            <ion-datetime-button style="font-size: 22px; border-radius: 5px" fill="clear" [datetime]="dateTimeId">
            </ion-datetime-button>
            <ion-modal #modal [keepContentsMounted]="true" [isOpen]="isOpen">
                <ng-template>
                    <ion-datetime
                        [id]="dateTimeId"
                        [firstDayOfWeek]="1"
                        (ionChange)="onDateSelect()"
                        [(ngModel)]="selectedDate"
                        presentation="date"></ion-datetime>
                </ng-template>
            </ion-modal>
        </ion-col>
        <ion-col class="ion-padding-horizontal ion-no-padding" size="auto" style="height: 64px"> </ion-col>
        <ion-col class="ion-no-padding" style="height: 64px; display: flex; justify-content: flex-end">
            <div style="display: flex; align-items: center; justify-content: flex-end">
                <ion-col
                    class="ion-padding-horizontal ion-no-padding"
                    style="height: 64px; display: flex; justify-content: flex-end; align-items: center">
                    <ion-button
                        (click)="slidePrev()"
                        fill="clear"
                        shape="round"
                        strong="true"
                        style="width: 36px"
                        size="middle"
                        class="ion-no-padding">
                        <ion-icon slot="icon-only" style="color: white" name="chevron-back-outline"></ion-icon>
                    </ion-button>
                    <ion-button
                        (click)="slideToday()"
                        fill="clear"
                        shape="round"
                        strong="true"
                        style="width: 36px"
                        size="middle"
                        class="ion-no-padding">
                        <ion-icon slot="icon-only" style="color: white" name="today-outline"></ion-icon>
                    </ion-button>
                    <ion-button
                        (click)="slideNext()"
                        fill="clear"
                        shape="round"
                        style="width: 36px"
                        strong="true"
                        size="middle"
                        class="ion-no-padding">
                        <ion-icon slot="icon-only" style="color: white" name="chevron-forward-outline"></ion-icon>
                    </ion-button>
                </ion-col>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="ion-no-padding">
            <slider-event [events]="_todayEvents" [format]="format" [isLoading]="loading()"></slider-event>
            @if (_todayEvents?.length == 0 && !loading()) {
            <ion-text style="text-align: center; color: white">
                <h4>{{'CALENDAR_EVENT.MESSAGE.NO_UPCOMING_EVENT' | translate}}</h4>
            </ion-text>
            }
        </ion-col>
    </ion-row>
</ion-grid>
