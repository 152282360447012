import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    NgZone,
    signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AlertController,
    IonButton,
    IonCol,
    IonDatetime,
    IonDatetimeButton,
    IonGrid,
    IonIcon,
    IonModal,
    IonRow,
    IonText,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { addDays, subDays } from 'date-fns';
import { addIcons } from 'ionicons';
import { chevronBackOutline, chevronForwardOutline, todayOutline } from 'ionicons/icons';
import { IUser } from '../../core/model/user.model';
import { CalendarEvent, CalendarEventSearch } from '../../model/calendar-event.model';
import { TimeFormatType } from '../../model/school.model';
import { CalendarEventService } from '../../service/calendar-event.service';
import { PreferenceService } from '../../service/preference.service';
import { today, utcDate } from '../../util/date-util';
import { SliderEvent } from './calendar/slider-event';

@Component({
    selector: 'app-daily-calendar',
    templateUrl: './daily-calendar.html',
    styleUrls: ['./daily-calendar.scss'],
    standalone: true,
    imports: [
        FormsModule,
        SliderEvent,
        FontAwesomeModule,
        TranslateModule,
        IonGrid,
        IonRow,
        IonCol,
        IonDatetimeButton,
        IonModal,
        IonDatetime,
        IonButton,
        IonIcon,
        IonText,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyCalendarComponent {
    static nextId = 0;
    dateTimeId = `daily-datetime-${DailyCalendarComponent.nextId++}`;
    @ViewChild(IonModal) modal: IonModal;
    @ViewChild(SliderEvent) sliderEvent: SliderEvent;

    isOpen = false;
    selectedDate: string = today().toISOString();
    account: IUser;
    _todayEvents: CalendarEvent[] = [];
    format: TimeFormatType;
    loading = signal(false);
    constructor(
        public alertCtrl: AlertController,
        private readonly zone: NgZone,
        private cdr: ChangeDetectorRef,
        public calendarService: CalendarEventService,
        private preference: PreferenceService
    ) {
        this.preference.user$.pipe(takeUntilDestroyed()).subscribe(state => {
            this.account = state.user;
            if (state.authenticated && this.account?.activeSchool?.id) {
                this.loadEvents();
            } else {
                this._todayEvents = [];
            }
        });
        addIcons({ chevronBackOutline, todayOutline, chevronForwardOutline });
    }
    ionViewWillEnter() {
        if (!this.selectedDate) {
            this.selectedDate = today().toISOString();
        }
    }
    loadEvents(event?: any) {
        this.loading.set(true);
        console.log('Loading Events');
        if (!this.selectedDate) {
            this.selectedDate = today().toISOString();
        }
        const eventSearchDTO = new CalendarEventSearch(
            utcDate(new Date(this.selectedDate)),
            this.account.activeSchool.id,
            null,
            this.account.student?.userId
        );

        this.calendarService.getTodayEvents(eventSearchDTO).subscribe(
            todayResponse => {
                this._todayEvents = todayResponse.body;
                console.log('EVENTS', this._todayEvents);
            },
            () => {},
            () => {
                this.loading.set(false);
                if (event) {
                    event.target.complete();
                }
                this.cdr.detectChanges();
            }
        );
    }

    onDateSelect() {
        this.isOpen = false;
        this.modal.dismiss();
        console.log('DATE SELECT', this.selectedDate);
        this.loadEvents();
        this.cdr.detectChanges();
    }

    onDateSelected(date: string) {
        console.log('DATE', date);
        setTimeout(() => {
            this.zone.run(() => {
                this.selectedDate = date;
                this.cdr.detectChanges();
            });
        }, 0);
        console.log('SELECTED DATE', this.selectedDate);
        this.loadEvents();
    }

    slideNext() {
        setTimeout(() => {
            this.selectedDate = addDays(new Date(this.selectedDate), 1).toISOString();
            this.loadEvents();
            this.cdr.detectChanges();
        }, 300);
    }
    slidePrev() {
        setTimeout(() => {
            this.selectedDate = subDays(new Date(this.selectedDate), 1).toISOString();
            this.loadEvents();
            this.cdr.detectChanges();
        }, 300);
    }
    slideToday() {
        this.selectedDate = today().toISOString();
        this.loadEvents();
        this.cdr.detectChanges();
    }
}
