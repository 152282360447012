<ion-header collapse="fade" class="toolbar-shadow">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Home</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-refresher slot="fixed" [pullFactor]="0.5" [pullMin]="100" [pullMax]="200" (ionRefresh)="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <app-daily-calendar></app-daily-calendar>
    <ion-grid>
        <ion-row>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/app/tabs/schedule" class="menu-item">
                    <fa-icon class="menu-item-icon" [icon]="['fal', 'calendar-clock']"></fa-icon>
                    <div class="menu-item-caption">
                        <div>{{'NAV.CALENDAR_EVENT.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button color="tertiary" routerLink="/app/tabs/courses" class="menu-item">
                    <fa-icon
                        class="menu-item-icon"
                        style="padding-right: 8px"
                        [icon]="['fal', 'chalkboard-user']"></fa-icon>
                    <div class="menu-item-caption">
                        <div style="padding-right: 4px">{{'NAV.COURSES.TITLE' | translate}}</div>
                    </div>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
